import styled from 'styled-components'
import { rem } from 'polished'

import LayoutContainer from 'components/Common/LayoutContainer'

export const TypesPageContainer = styled(LayoutContainer)`
  padding-top: ${rem(16)};
  padding-bottom: ${rem(16)};

  > * + * {
    margin-top: ${rem(12)};
  }
`
