import React, { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import { SelectionOption } from 'components/SearchV2/Components/FilterPanel/FilterPanel'
import { HolidayTypeToIconMap, getTopNFilterOptions, getHolidayTypeIcon } from 'lib/search/searchUtils'
import { connect } from 'react-redux'
import ToggleButton from 'components/Luxkit/ToggleButton'

import { useAppDispatch } from 'hooks/reduxHooks'
import { replace } from 'connected-react-router'
import { deleteSearchParams, setManySearchParamValues } from 'lib/url/searchUrlUtils'
import { without } from 'lib/array/arrayUtils'
import getOfferListKey from 'lib/offer/offerListKey'

interface Props {
  filters: App.OfferListFilters;
  search: string;
}

interface MappedStateProps {
  availableFilters: App.OfferListFilterOptions;
}

const sortAndLimitFilter: Record<string, {sortBy: string, limit: number}> = {
  Trending: { sortBy: 'sales', limit: 100 },
}

const sortFilters: Record<string, string> = {
  'Ending Soon': 'endingsoon',
  'Latest/New Offer': 'newest',
  'Best Value': 'value',
}

const amenityFilters: Record<string, string> = {
  'Private Pool': 'Private pool',
  'Ocean Views': 'Ocean views',
}

function AnywhereFilterTabs(props: Props & MappedStateProps) {
  const { filters, availableFilters, search } = props
  const [currentTab, setCurrentTab] = useState<string>(() => {
    if (filters.holidayTypes?.[0]) {
      return filters.holidayTypes?.[0]
    }
    if (
      filters.sortBy &&
      filters.limit &&
      Object.values(sortAndLimitFilter).some(f => f.sortBy === filters.sortBy && f.limit === filters.limit)
    ) {
      return Object.keys(sortAndLimitFilter).find((key) => sortAndLimitFilter[key].sortBy === filters.sortBy) ?? 'All'
    }
    if (filters.amenities?.[0] && Object.values(amenityFilters).includes(filters.amenities?.[0])) {
      return Object.keys(amenityFilters).find((key) => amenityFilters[key] === filters.amenities?.[0]) ?? 'All'
    }
    if (filters.sortBy && Object.values(sortFilters).includes(filters.sortBy)) {
      return Object.keys(sortFilters).find((key) => sortFilters[key] === filters.sortBy) ?? 'All'
    }
    return 'All'
  })
  const [availableFiltersInState, setAvailableFiltersInState] = useState<App.OfferListFilterOptions | null>(null)
  const firstFilter = useRef(currentTab)

  useEffect(() => {
    if (availableFilters !== undefined) {
      setAvailableFiltersInState(availableFilters)
    }
  }, [availableFilters])

  const dispatch = useAppDispatch()

  const handleSelect = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    const selectionId = String(event.currentTarget.dataset.selectionid)
    if (currentTab === selectionId || selectionId === 'All') {
      setCurrentTab('All')
      dispatch(
        replace({
          search: deleteSearchParams(
            search,
            'holidayTypes',
            'amenities',
            'sortBy',
            'limit',
          ),
        }),
      )
    } else if (Object.keys(sortAndLimitFilter).includes(selectionId)) {
      setCurrentTab(selectionId)
      dispatch(
        replace({
          search: setManySearchParamValues(
            search,
            [
              { paramName: 'holidayTypes', paramValue: undefined },
              { paramName: 'amenities', paramValue: undefined },
              { paramName: 'sortBy', paramValue: sortAndLimitFilter[selectionId].sortBy },
              { paramName: 'limit', paramValue: sortAndLimitFilter[selectionId].limit },
            ],
          ),
        }),
      )
    } else if (Object.keys(sortFilters).includes(selectionId)) {
      setCurrentTab(selectionId)
      dispatch(
        replace({
          search: setManySearchParamValues(
            search,
            [
              { paramName: 'holidayTypes', paramValue: undefined },
              { paramName: 'amenities', paramValue: undefined },
              { paramName: 'sortBy', paramValue: sortFilters[selectionId] },
              { paramName: 'limit', paramValue: undefined },
            ],
          ),
        }),
      )
    } else if (Object.keys(amenityFilters).includes(selectionId)) {
      setCurrentTab(selectionId)
      dispatch(
        replace({
          search: setManySearchParamValues(
            search,
            [
              { paramName: 'holidayTypes', paramValue: undefined },
              { paramName: 'amenities', paramValue: amenityFilters[selectionId] },
              { paramName: 'sortBy', paramValue: undefined },
              { paramName: 'limit', paramValue: undefined },
            ],
          ),
        }),
      )
    } else {
      setCurrentTab(selectionId)
      dispatch(
        replace({
          search: setManySearchParamValues(
            search,
            [
              { paramName: 'holidayTypes', paramValue: selectionId },
              { paramName: 'amenities', paramValue: undefined },
              { paramName: 'sortBy', paramValue: undefined },
              { paramName: 'limit', paramValue: undefined },
            ],
          ),
        }),
      )
    }
  }, [currentTab, dispatch, search])

  const availableHolidayTypeFilters = availableFiltersInState?.filters?.holidayTypes

  const topHolidayTypes = getTopNFilterOptions(availableHolidayTypeFilters, availableFiltersInState?.filterOrder?.holidayTypes, 30)

  if (!topHolidayTypes) {
    return null
  }

  const customerFilters: Array<[string, number, number]> = [
    ...Object.keys(sortAndLimitFilter),
    ...Object.keys(amenityFilters),
    ...Object.keys(sortFilters),
  ].map((filter) => ([filter, 100, 10]))

  const allFilters = [
    ...topHolidayTypes.slice(0, 6),
    ...customerFilters, // hardcode to the 7th position until we have tracking data
    ...topHolidayTypes.slice(6),
  ]

  const topHolidayTypeOptions = allFilters.map<SelectionOption>(
    ([holidayType]) => {
      return {
        icon: getHolidayTypeIcon(holidayType.toLowerCase()),
        text: holidayType,
        id: `${holidayType}-theme`,
      }
    },
  )

  const themeOptions: Array<SelectionOption> = [{
    icon: HolidayTypeToIconMap.All,
    text: 'All',
    id: 'All-theme',
  }]
  const options = themeOptions.concat(topHolidayTypeOptions)
  const firstSelectedOption = options.find((option) => option.text === firstFilter.current)
  const orderedOptions: Array<SelectionOption> = []
  if (firstSelectedOption) {
    orderedOptions.push(firstSelectedOption, ...without(options, firstSelectedOption))
  } else {
    orderedOptions.push(...options)
  }

  return (
    <CardCarousel gap={0}>
      {orderedOptions.map((option) => (
        <ToggleButton
          kind="primary"
          orientation="vertical"
          key={option.id}
          startIcon={option.icon}
          title={option.text}
          isSelected={currentTab === option.text}
          data-selectionid={option.text}
          onClick={handleSelect}
        />))
      }
    </CardCarousel>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState, ownProps) => {
  const offerListKey = getOfferListKey(ownProps.filters)
  return {
    availableFilters: appState.offer.offerListFilterOptions[offerListKey],
  }
})(AnywhereFilterTabs)
