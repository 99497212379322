import { HOTELS_ENABLED_FILTERS } from 'constants/search'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { createSelector } from 'reselect'

export const getHotelEnabledFilters = createSelector(
  isLuxPlusEnabled,
  (luxPlusEnabled) => {
    return {
      ...HOTELS_ENABLED_FILTERS,
      luxPlusFeatures: luxPlusEnabled,
    }
  },
)
