import { useEffect, useState } from 'react'

enum Step {
  Typing,
  Pausing,
  Deleting,
}

const TYPING_INTERVAL = 150
const PAUSING_MS = 1000
const DELETING_TIME = 50

export default function useTypingAnimation(textList: Array<string>, disable?: boolean): string {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [step, setStep] = useState(Step.Typing)
  const [typedText, setTypedText] = useState('')
  useEffect(() => {
    switch (step) {
      case Step.Typing: {
        const nextText = textList[selectedIndex].slice(0, typedText.length + 1)

        if (nextText === typedText) {
          setStep(Step.Pausing)
          return
        }

        const timeout = setTimeout(() => {
          setTypedText(nextText)
        }, TYPING_INTERVAL)
        return () => clearTimeout(timeout)
      }

      case Step.Deleting: {
        if (!typedText) {
          const nextIndex = selectedIndex + 1
          setSelectedIndex(textList[nextIndex] ? nextIndex : 0)
          setStep(Step.Typing)
          return
        }
        const nextRemaining = textList[selectedIndex].slice(0, typedText.length - 1)

        const timeout = setTimeout(() => {
          setTypedText(nextRemaining)
        }, DELETING_TIME)

        return () => clearTimeout(timeout)
      }

      case Step.Pausing:
      default:
        if (disable) return

        const timeout = setTimeout(() => {
          setStep(Step.Deleting)
        }, PAUSING_MS)
        return () => clearTimeout(timeout)
    }
  }, [typedText, textList, step, selectedIndex, disable])
  return typedText
}
