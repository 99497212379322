import HeroImage from 'content/components/HeroImage'
import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import LayoutContainer from 'components/Common/LayoutContainer'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { GlobalSearchContainerPaneRoot } from './GlobalSearchContainerPane'
import Heading from 'components/Luxkit/Typography/Heading'
import GlobalSearchQuickFiltersContainer from './QuickFilters/GlobalSearchQuickFiltersContainer'

const HeroContainer = styled(HeroImage)`
  position: relative;
  padding: ${rem(32)} 0;

  ${mediaQueryUp.tablet} {
    justify-content: start;
    padding: ${rem(60)} 0;
  }
`

const HeroContent = styled(LayoutContainer)`
  position: relative;

  > .hero-heading + ${GlobalSearchContainerPaneRoot} {
    margin-top: ${rem(24)};
  }

  > * + ${GlobalSearchQuickFiltersContainer} {
    margin-top: ${rem(16)};
  }
`

const HeroDimmedOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
`

interface Props {
  children: React.ReactNode
  heroImageId: string
  title: string
  className?: string;
  gravity?: 'auto' | 'center' | 'east' | 'north' | 'northeast' | 'northwest' | 'south' | 'southeast' | 'southwest' | 'west' // auto is centre
  aspectRatio?: string;
}

const GlobalSearchHeroContainer = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { aspectRatio, children, heroImageId, title, className, gravity } = props

  return <HeroContainer
    alt={title}
    fit="center"
    gravity={gravity ?? 'auto'}
    id={heroImageId}
    mobileAspectRatio={aspectRatio ?? '8:9'}
    tabletAspectRatio="10:3"
    className={className}
  >
    <HeroDimmedOverlay aria-hidden="true" role="presentation" />
    <HeroContent ref={ref}>
      <Heading colour="neutral-eight" variant="heading2" className="hero-heading">
        {title}
      </Heading>
      {children}
    </HeroContent>
  </HeroContainer>
})

GlobalSearchHeroContainer.displayName = 'GlobalSearchHeroContainer'

export default GlobalSearchHeroContainer
