import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import ResponsiveImage from 'components/Common/ResponsiveImage'
import { mediaQueryUp } from 'components/utils/breakpoint'

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${rem(322)};
  padding: ${rem(32)} ${rem(20)};

  ${mediaQueryUp.tablet} {
    min-height: ${rem(289)};
    padding: ${rem(40)} ${rem(24)};
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

interface Props extends React.ComponentProps<typeof ResponsiveImage> {
  children?: React.ReactNode;
}

function HeroImage(props: Props) {
  const {
    alt,
    children,
    className,
    fit = 'center',
    id,
    tabletAspectRatio = '16:9',
    mobileAspectRatio = '4:3',
    ...restOfImageProps
  } = props

  return (
    <Root className={className}>
      <ImageWrapper>
        <ResponsiveImage
          {...restOfImageProps}
          mobileAspectRatio={mobileAspectRatio}
          tabletAspectRatio={tabletAspectRatio}
          id={id}
          alt={alt}
          fit={fit}
          loading="eager"
        />
      </ImageWrapper>
      {children}
    </Root>
  )
}

export default HeroImage
