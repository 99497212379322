import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { getUrlOfferListFilters } from 'selectors/routerSelectors'
import { isSearchEnabled } from 'lib/search/searchUtils'
import HeadData from 'components/Common/HeadData'
import PartnershipBanner from 'components/Common/Vendors/Partnership/PartnershipBanner'
import { LUXURY_ESCAPES } from 'constants/brands'
import { ANYWHERE_PLACE_ID, TYPE_PAGE_LIMIT, ANYWHERE_NAME } from 'constants/search'

import config from 'constants/config'
import { PARTNERSHIP_NON_VIRGIN_REGIONS } from 'constants/config/region'

import { TypesPageContainer } from './styles'
import { fetchListBanners } from 'actions/ContentActions'
import { fetchHotelPopularHolidayTypes } from 'actions/DestinationAlertsActions'
import BannerCarousel from 'components/Common/Banner/BannerCarousel'
import { BANNER_LOCATION_HOTELS_AND_RESORTS } from 'constants/bannerLocations'
import { FLASH_HOTELS_AND_TAO_OFFERS_TYPES, HOTEL_PAGE_OFFER_TYPES } from 'constants/offerList'
import Heading from 'components/Luxkit/Typography/Heading'
import { frontPageSortByTypes } from 'constants/offerListFilters'
import GlobalSearchHeroContainer from 'components/Search/GlobalSearch/GlobalSearchHeroContainer'
import GlobalSearchContainerPane, { GlobalSearchContainerPaneContent } from 'components/Search/GlobalSearch/GlobalSearchContainerPane'
import SearchTrendingDestinations from 'components/Search/SearchForm/SearchTrendingDestinations'
import GlobalHotelsSearchTabContent from 'components/Search/GlobalSearch/GlobalSearchVerticalsTabContents/GlobalHotelsSearchTabContent'
import isAnyFilterApplied from 'lib/offer/filtersUtils'
import GlobalMobileSearchHeroBar from 'components/SearchV2/Components/Mobile/GlobalMobileSearchHeroBar'
import OfferList, { OfferListV2Item } from 'components/OfferList/OfferList'
import { OfferListEventsProvider } from 'components/OfferList/OfferListEventsContext'
import PromptCardSelector from 'components/Common/StickyPromptCard/PromptCardSelector'
import { VIRGIN_VELOCITY_PARTNERSHIP_CODE } from 'constants/partnerships'
import AnywhereFilterTabs from '../HotelSearchPage/AnywhereFilterTabs'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import styled from 'styled-components'
import OfferCountAndSort from '../HotelSearchPage/OfferCountAndSort'
import { rem } from 'polished'
import AnalyticsPageContext, { AnalyticsPage } from 'contexts/Analytics/analyticsPageContext'
import ErrorBoundary from 'components/Common/ErrorBoundary/ErrorBoundary'
import { isManualSortingEnabledFor } from 'selectors/featuresSelectors'
import { ALL_HOTEL_TYPES, OFFER_TYPE_VILLA } from 'constants/offer'
import { getListName } from 'analytics/enhanced-ecommerce'
import useOfferListTracking from 'hooks/Offers/useOfferListTracking'
import { getHotelEnabledFilters } from 'selectors/filters/filterSelectors'
import Pane from 'components/Common/Pane'
import RecentSearches from 'home/components/RecentSearches/RecentSearches'

const Page = styled.div`
  padding-bottom: ${rem(32)};
`

const TabsContainer = styled(LayoutContainer)`
  > * + * {
    padding-top: ${rem(16)};
  }
`

const Wrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  padding: ${rem(16)} 0;
`

interface MappedStateProps {
  windowSearch: string;
  partnership: App.Partnership;
  trendingDestinations: Array<App.TrendingDestination>;
  filters: App.OfferListFilters;
  regionCode: string;
  isSearchEnabled: boolean;
  banners: Array<App.ListBanner>;
}

interface MappedDispatchProps {
  fetchListBanners: Utils.ActionDispatcher<typeof fetchListBanners>;
}

const hotelsDestinations = ['Bali', 'Phuket', 'Europe', 'Australia']

function HotelsPage({
  filters,
  partnership,
  regionCode,
  isSearchEnabled,
  banners,
  fetchListBanners,
  trendingDestinations,
  windowSearch,
}: MappedStateProps & MappedDispatchProps) {
  const isManualSortingEnabled = isManualSortingEnabledFor('hotels')
  useEffect(() => {
    fetchListBanners(BANNER_LOCATION_HOTELS_AND_RESORTS)
  }, [fetchListBanners])

  const dispatch = useAppDispatch()

  const hasFilters = isAnyFilterApplied(filters)

  const tracking = useOfferListTracking(
    getListName('OfferList', 'HOTELS', 'Hotels Page Offer List'),
    { ias: { list: 'hotels' } },
  )

  const offerListExtras: Array<OfferListV2Item> = [{
    id: 'title',
    position: 'start',
    element: <Heading variant="heading2" align="center">Hotels & Resorts</Heading>,
    show: !isSearchEnabled,
  }, {
    id: 'partnership',
    position: 'start',
    element: <PartnershipBanner bannerId="types-page" />,
    show: !!partnership && partnership.code !== VIRGIN_VELOCITY_PARTNERSHIP_CODE && !PARTNERSHIP_NON_VIRGIN_REGIONS.includes(regionCode),
  }, {
    position: 1,
    id: 'bannerCarousel',
    element: <BannerCarousel banners={banners} />,
    show: !!banners?.length,
  }]

  const headTitle = config.BRAND === LUXURY_ESCAPES ? 'Luxury Hotels & Resorts at the Best Prices on Earth' : 'Hotels & Resorts'
  const description = config.BRAND === LUXURY_ESCAPES ? 'Book exclusive special offers for luxury hotels and accommodation at insider prices. Best Price Guarantee.' : undefined

  const trendingDestinationsLabels = useMemo(() => (
    trendingDestinations.length ? trendingDestinations.map(destination => destination.primaryText) : hotelsDestinations
  ), [trendingDestinations])

  const finalFilters: App.OfferListFilters = useMemo(() => {
    if (isManualSortingEnabled && !hasFilters && !filters.sortBy) {
      return {
        offerTypes: ALL_HOTEL_TYPES,
        sortBy: 'vertical-hotel',
        limit: TYPE_PAGE_LIMIT,
      }
    }

    const offerTypes = !hasFilters ? HOTEL_PAGE_OFFER_TYPES(regionCode) : FLASH_HOTELS_AND_TAO_OFFERS_TYPES
    if (config.ENABLE_VILLA_RENTALS) {
      offerTypes.push(OFFER_TYPE_VILLA)
    }

    return {
      ...filters,
      offerTypes,
      frontPageVisibleOnly: true,
      limit: TYPE_PAGE_LIMIT,
      sortBy: filters.sortBy ?? 'homepage',
    }
  }, [filters, hasFilters, isManualSortingEnabled, regionCode])

  const mapViewURL = `/search/map?adults=2&children=none&destinationId=${ANYWHERE_PLACE_ID}&destinationName=${ANYWHERE_NAME}`

  useEffect(() => {
    dispatch(fetchHotelPopularHolidayTypes())
  }, [dispatch])

  const enabledFilters = useAppSelector(getHotelEnabledFilters)

  return <AnalyticsPageContext.Provider value={AnalyticsPage.verticalPage} >
    <Page>
      <HeadData title={`${headTitle} - ${config.title} ${regionCode}`} description={description} />
      <ErrorBoundary fallback={null}>
        <PromptCardSelector tripReengagement />
      </ErrorBoundary>
      {isSearchEnabled && <GlobalSearchHeroContainer
        heroImageId={config.searchFormSection.hotelsImageId}
        title="Hotels & Resorts"
      >
        <GlobalSearchContainerPane displayMobile={!config.USE_INSPIRATIONAL_SEARCH}>
          <GlobalSearchContainerPaneContent>
            <GlobalHotelsSearchTabContent />
          </GlobalSearchContainerPaneContent>
        </GlobalSearchContainerPane>

        {config.USE_INSPIRATIONAL_SEARCH && <GlobalMobileSearchHeroBar
          placeholder="Search hotel offers in"
          placeholderTextList={trendingDestinationsLabels}
        />}

        <SearchTrendingDestinations
          titleColour="neutral-eight"
          titleWeight="bold"
        />
      </GlobalSearchHeroContainer>}
      <RecentSearches/>
      <Wrapper>
        <TabsContainer>
          <Heading variant="heading3">
            Explore today's <i>exclusive</i> offers
          </Heading>
          <AnywhereFilterTabs filters={finalFilters} search={windowSearch} />
        </TabsContainer>
      </Wrapper>
      <Pane type="light">
        <TypesPageContainer>
          <OfferCountAndSort
            mainListTotal={0}
            sortOptions={frontPageSortByTypes}
            mapViewUrl={mapViewURL}
            filters={finalFilters}
            windowSearch={windowSearch}
            shouldShowFilters
            showOfferCount={false}
            showHolidayTypes={false}
            enabledFilters={enabledFilters}
          />
          <OfferListEventsProvider tracking={tracking}>
            <OfferList
              filters={finalFilters}
              interstitials={offerListExtras}
            />
          </OfferListEventsProvider>
        </TypesPageContainer>
      </Pane>
    </Page>
  </AnalyticsPageContext.Provider>
}

const mapStateToProps = (state: App.State): MappedStateProps => ({
  windowSearch: state.router.location.search,
  partnership: state.geo.partnership,
  trendingDestinations: state.destination.trendingDestinations,
  filters: getUrlOfferListFilters(state),
  regionCode: state.geo.currentRegionCode,
  isSearchEnabled: isSearchEnabled(),
  banners: state.content.listBanners?.hotelsAndResorts,
})

const mapDispatchToProps: MappedDispatchProps = {
  fetchListBanners,
}

export default connect(mapStateToProps, mapDispatchToProps)(HotelsPage)
