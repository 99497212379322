import useTypingAnimation from 'hooks/useTypingAnimation'
import React from 'react'

interface Props {
  textList: Array<string>;
  disable?: boolean;
}

function TypingAnimation({ textList, disable }: Props) {
  const typedText = useTypingAnimation(textList, disable)

  return (
    <span>{typedText}</span>
  )
}

export default TypingAnimation
