import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { rem } from 'polished'

import partnershipRewardMessage from 'lib/partnership/partnershipRewardMessage'

import Pane from 'components/Common/Pane'
import PaneBody from 'components/Common/Pane/PaneBody'
import { mediaQueryUp } from 'components/utils/breakpoint'
import ResponsiveImage from 'components/Common/ResponsiveImage'
import TextLink from 'components/Luxkit/TextLink'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { addGTMEvent } from 'api/googleTagManager'
import { promotionBanner } from 'analytics/promotions'

const PartnershipBannerPane = styled(Pane)`
  width: 100%;
  padding: ${rem(8)};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
`

const BannerContent = styled(PaneBody)`
  display: flex;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-left: ${rem(8)};
  }

  ${mediaQueryUp.desktop} {
    > * + * {
      margin-left: ${rem(16)};
    }
  }
`

const StyledImage = styled(ResponsiveImage)`
  max-width: ${rem(135)};
`

interface Props {
  partnership: App.Partnership;
  bannerId: string;
}
class PartnershipBannerContainer extends Component<React.PropsWithChildren<Props>> {
  trackContentImpression = () => {
    addGTMEvent(promotionBanner({ bannerId: this.props.bannerId, hitType: 'promotion-impression' }))
  }

  onClickTrack = () => {
    addGTMEvent(promotionBanner({ bannerId: this.props.bannerId, hitType: 'promotion-click' }))
  }

  render() {
    const {
      partnership,
    } = this.props

    return <PartnershipBannerPane>
      <BannerContent size="md-y">
        <StyledImage
        loading="lazy"
        mobileWidth="100px"
        tabletWidth="135px"
        format="png"
        id={partnership.programLogo.id}
        alt={`${partnership.programName} Logo`}
        onLoad={this.trackContentImpression}
      />
        <BodyText variant="medium">
          Earn {partnershipRewardMessage(partnership)}{partnership.hasBurn ?
        ` OR use your ${partnership.rewardName} to put towards your next escape` :
        `. Link your ${partnership.programName} account to earn ${partnership.rewardName} on eligible bookings `}{' '}
          <TextLink
          onClick={this.onClickTrack}
          to={`/${partnership.landingPage}`}
        >
            Learn more
          </TextLink>
        </BodyText>
      </BannerContent>
    </PartnershipBannerPane>
  }
}

const mapStateToProps = (state: App.State) => ({
  partnership: state.geo.partnership,
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(PartnershipBannerContainer)
