import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TypingAnimation from 'components/Common/TypingAnimation'
import { mediaQueryUp } from 'components/utils/breakpoint'
import useGlobalSearchVerticalTabs from 'hooks/GlobalSearch/useGlobalSearchVerticalTabs'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import useCurrentGlobalSearchVerticalPage from 'hooks/GlobalSearch/useCurrentGlobalSearchVerticalPage'

const SearchBar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  /* these are in \PX value because we don't want them to scale on small screens */
  padding: 6px;
  padding-left: 12px;
  border-radius: ${props => props.theme.borderRadius.S};
  box-shadow: ${props => props.theme.shadow.flat.small};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  overflow: hidden;
  white-space: nowrap;
  margin: ${rem(16)} 0;

  ${mediaQueryUp.tablet} {
    display: none;
  }

  & > * + * {
    /* Right margin instead of left because it's row-reverse */
    margin-right: ${rem(4)};
  }
`

interface Props {
  placeholder?: string;
  placeholderTextList?: Array<string>;
}

function GlobalMobileSearchHeroBar(props: Props) {
  const { placeholder, placeholderTextList } = props
  const globalSearchVerticalTabs = useGlobalSearchVerticalTabs()
  const currentSearchVerticalPage = useCurrentGlobalSearchVerticalPage()
  const { openedSearchVertical, toggleSearchVertical } = useGlobalSearchURLHashVertical()

  const toggleSearch = useCallback(() => {
    if (openedSearchVertical) {
      toggleSearchVertical('none')
    } else {
      if (!currentSearchVerticalPage) {
        toggleSearchVertical([...globalSearchVerticalTabs.keys()][0])
      } else {
        toggleSearchVertical(currentSearchVerticalPage)
      }
    }
  }, [currentSearchVerticalPage, globalSearchVerticalTabs, openedSearchVertical, toggleSearchVertical])
  const textList = useMemo(() => [...globalSearchVerticalTabs.values()].map(tab => tab.label),
    [globalSearchVerticalTabs])

  return (
    <SearchBar role="presentation" onClick={toggleSearch}>
      <IconButton kind="primary" size="small"><LineSearchIcon /></IconButton>
      <BodyText variant="small" colour="neutral-two">
        {placeholder || 'Search great offers for'} &nbsp;
        <BodyText as="span" colour="neutral-one" variant="small" weight="bold">
          <TypingAnimation textList={placeholderTextList || textList} disable={textList.length === 1} />
        </BodyText>
      </BodyText>
    </SearchBar>
  )
}

export default GlobalMobileSearchHeroBar
